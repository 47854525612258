@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap");

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 2px !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 2px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiButton-containedPrimary {
  background-color: #00a950 !important;
  font-weight: 700 !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid rgba(0, 169, 80, 0.5) !important;
  color: #00a950 !important;
  font-weight: 700 !important;

  &:hover {
    background-color: rgba(0, 169, 80, 0.04) !important;
  }
}

.MuiCard-root {
  border-radius: 1.5rem !important;
  background: #fff !important;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
  padding: 2.5rem;

  @media (max-width: 768px) {
    padding: 1.8rem 1.4rem;
  }
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: Helvetica !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  margin-bottom: 0.5rem !important;
}
